/**
 * This is the central place where all possible tracking events must be defined.
 *
 * Naming conventions:
 *
 * We use the object-action framework for naming our events.
 *
 * Constant name (key): {OBJECT}_{ACTION}
 * Event name (value): {Object} {action}
 *
 * Example event: "Material downloaded"
 *    Material = Object (Proper Case)
 *    downloaded = action (always lowercase)
 *
 * Example event 2: "Material downloaded all"
 *    Material = Object (Proper Case)
 *    downloaded all = action (always lowercase)
 *
 * Example event 3: "Lead Status updated"
 *    Lead Status = Object (Proper Case)
 *    updated = action (always lowercase)
 *
 * More on naming conventions: https://segment.com/academy/collecting-data/naming-conventions-for-clean-data/
 */

const trackingEvents = Object.freeze({
  LEAD_STATUS_UPDATED: 'Lead Status updated',
  MATERIAL_DOWNLOADED: 'Material downloaded',
  MATERIAL_DOWNLOADED_ALL: 'Material downloaded all',
  MATERIAL_DOWNLOADED_CAMPAIGN: 'Material downloaded campaign'
})

export default trackingEvents
