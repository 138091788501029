<template>
  <v-dialog
    v-model="dialogOpen"
    max-width="1200"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        :class="{'w-full':$vuetify.breakpoint.smAndDown}"
        color="primary"
        :small="small"
        outlined
        v-on="on"
      >
        <v-icon left>
          mdi-xml
        </v-icon>
        Einbindungs-Code
      </v-btn>
    </template>
    <v-card>
      <v-card-title
        class="pa-0"
      >
        <v-toolbar
          color="primary"
          dark
          flat
        >
          <v-toolbar-title>Einbindungs-Code</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="close"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
      </v-card-title>

      <v-card-text
        class="pb-0 mt-2"
        :style="cssProps"
      >
        <v-tabs
          v-if="hasExtendedOption"
          v-model="tab"
        >
          <v-tab>
            Kompakte Einbindung
          </v-tab>
          <v-tab>
            Umfangreiche Einbindung
          </v-tab>
        </v-tabs>

        <v-tabs-items
          v-if="hasExtendedOption"
          v-model="tab"
          class="mt-2 mb-6"
        >
          <v-tab-item>
            <span>Die kompakte Einbindung passt auf jede Website und gibt Ihnen die Flexibilität den Bereich um das Widget herum individuell nach Ihren Wünschen zu gestalten.</span>
          </v-tab-item>

          <v-tab-item>
            <span>Die umfangreiche Einbindung bringt mehr Informationen und eine Vorschau auf den Ratgeber direkt mit
              auf Ihre Website. Diese Lösung benötigt daher mehr Platz als die kompakte Variante.</span>
          </v-tab-item>
        </v-tabs-items>

        <v-row
          class="mb-2"
          :class="{'mt-2': !hasExtendedOption}"
        >
          <v-col
            cols="12"
            md="6"
          >
            <h3 class="mb-4">
              Code für die {{ textCodeTitle }} Einbindung:
            </h3>
            <div>
              <div
                style="position:relative"
                class="mb-2"
              >
                <div class="snippet rounded-lg grey lighten-4 pa-4">
                  <span
                    id="snippet"
                    class="text-body-1"
                  >
                    {{ snippet }}
                  </span>
                </div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      style="position:absolute; bottom: -20px; right: -20px"
                      class="mx-2 elevation-1"
                      fab
                      small
                      color="primary"
                      v-bind="attrs"
                      @click="copy"
                      v-on="on"
                    >
                      <v-icon dark>
                        mdi-content-copy
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Einbindungs-Code Kopieren</span>
                </v-tooltip>
              </div>

              <div>
                <span
                  v-if="copyState==='success'"
                  class="success--text"
                >
                  <v-icon
                    color="success"
                    class="mt-n1"
                  >
                    mdi-check
                  </v-icon>
                  Code wurde in die Zwischenablage kopiert
                </span>
                <span
                  v-if="copyState==='error'"
                  class="error--text"
                >
                  <v-icon
                    color="error"
                    class="mt-n1"
                  >
                    mdi-close
                  </v-icon>
                  Problem beim kopieren.
                </span>
              </div>

              <div class="mt-6">
                <p v-if="landingpageType === 'RETURN_CALL'">
                  Die telefonische Beratung kann als Formular oder kurze Klickstrecke („Wizard“) eingebunden werden.
                  Zur Verwendung des Wizard muss das Feld <span class="rounded-lg grey lighten-4 pa-1 text-no-wrap">data-variant=“wizard”</span>
                  im Code mit angegeben werden.
                  Für die Variante als Formular muss das Feld <span class="rounded-lg grey lighten-4 pa-1 text-no-wrap">data-variant=“wizard”</span>
                  entfernt werden.
                </p>
                <p>
                  Binden Sie {{ readablePrefix }} <b>{{ readableLandingpage }}</b> ganz einfach auf Ihrer Website ein.
                  Platzieren Sie dafür den Code an genau der Stelle, an der der Inhalt angezeigt werden soll.
                  Dieses Script baut sich den passenden iFrame. Es wird kein weiterer Code benötigt.
                </p>

                <p class="mt-4">
                  Anleitung: Eine beispielhafte Implementierung finden Sie <a
                    :href="helpLinks.iFrameImplementation"
                    target="_blank"
                    rel="noopener noreferrer"
                  >hier</a>.
                </p>
              </div>
            </div>
          </v-col>
          <v-col
            v-if="$vuetify.breakpoint.mdAndUp"
            cols="12"
            md="6"
          >
            <h3 class="mb-4">
              Vorschau:
            </h3>

            <v-tabs-items
              v-model="tab"
              class="mt-2"
            >
              <v-tab-item>
                <div class="preview grey lighten-4">
                  <div class="wrapper d-flex justify-center">
                    <iframe
                      class="scaled-frame"
                      :src="`${companySiteUrl}/${landingpageSlug}/embed`"
                    />
                  </div>
                </div>
              </v-tab-item>

              <v-tab-item v-if="hasExtendedOption">
                <div class="preview d-flex justify-center grey lighten-4">
                  <div class="wrapper">
                    <iframe
                      class="scaled-frame"
                      :src="`${companySiteUrl}/${landingpageSlug}/embed?variant=extended`"
                    />
                  </div>
                </div>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click="close"
        >
          Schließen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import iFrameCssMap from './iFrameCssMap'
import helpLinks from '@/lib/helpLinks'

export default {
  props: {
    companySlug: { type: String, default: null },
    companySiteUrl: { type: String, default: '' },
    landingpageSlug: { type: String, default: null },
    readablePrefix: { type: String, default: null },
    readableLandingpage: { type: String, default: null },
    landingpageType: { type: String, default: '' },
    small: { type: Boolean, default: false }
  },
  data () {
    return {
      copyState: 'idle',
      dialogOpen: false,
      tab: 0,
      helpLinks
    }
  },
  computed: {
    snippet () {
      return `
      <script
      src="${process.env.VUE_APP_IFRAME_LOADER_URL}"
      data-company="${this.companySlug}"
      data-slug="${this.landingpageSlug}"
      ${['CHECKLIST', 'GUIDE'].includes(this.landingpageType) && this.tab === 1 ? 'data-variant="extended"' : ''}
      data-bottimmo defer>
      <\/script>`.replace(/\n/g, ' ') // eslint-disable-line no-useless-escape
    },
    hasExtendedOption () {
      return ['CHECKLIST', 'GUIDE'].includes(this.landingpageType)
    },
    cssProps () {
      const variant = this.tab === 1 ? 'extended' : 'basic'
      return {
        '--wrapper-width': iFrameCssMap[this.landingpageType][variant].wrapperWidth,
        '--iframe-zoom': iFrameCssMap[this.landingpageType][variant].zoom,
        '--iframe-width': iFrameCssMap[this.landingpageType][variant].width,
        '--iframe-height': iFrameCssMap[this.landingpageType][variant].height,
        '--iframe-margin-top': iFrameCssMap[this.landingpageType][variant].marginTop || 0,
        '--iframe-margin-left': iFrameCssMap[this.landingpageType][variant].marginLeft || 0
      }
    },
    textCodeTitle () {
      return this.hasExtendedOption ? this.tab === 0 ? 'kompakte' : 'umfangreiche' : ''
    }
  },

  watch: {
    tab (newValue, oldValue) {
      if (oldValue !== newValue) {
        this.copyState = 'idle'
      }
    }
  },
  methods: {
    copy () {
      navigator.clipboard.writeText(this.snippet)
        .then(() => {
          this.copyState = 'success'
        })
        .catch(() => {
          this.copyState = 'error'
        })
    },
    close () {
      this.dialogOpen = false
      this.copyState = 'idle'
    }

  }
}
</script>

<style scoped>
.preview{
  height: 400px;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.wrapper {
  width: var(--wrapper-width);
  height: 100%;
  padding: 0;
  overflow: hidden;
}

.scaled-frame {
  margin-top: var(--iframe-margin-top);
  margin-left: var(--iframe-margin-left);
  width: var(--iframe-width);
  height: var(--iframe-height);
  border: 0px;
  zoom: var(--iframe-zoom);
  -moz-transform: scale(var(--iframe-zoom));
  -moz-transform-origin: center center;
  -o-transform: scale(var(--iframe-zoom));
  -o-transform-origin:  center center;
  -webkit-transform: scale(var(--iframe-zoom));
  -webkit-transform-origin:  center center;
  border-radius: 5px;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  pointer-events: none;
  background:white;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
 .scaled-frame  { zoom: 1;  }
}

</style>
