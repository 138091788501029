/**
 * These are the social media images for the light product.
 * They are stored in the static bucket 'light-product' on AWS.
 * They try to follow the same object convention as regular materials.
 *
 * This is a temporary solution until we can map materials to various variants for landingpages.
 */
export default {
  immobilienbewertung: [
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-1.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-1.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-1.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-1.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-2.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-2.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-2.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-2.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-3.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-3.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-3.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-3.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-4.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-4.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-4.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-4.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-5.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-5.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-5.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-5.jpg?h=600'
          }
        }
      ]
    }
  ],
  'telefonische-beratung': [
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/telefonische-beratung/social-media-image-1.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/telefonische-beratung/social-media-image-1.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/telefonische-beratung/social-media-image-1.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/telefonische-beratung/social-media-image-1.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/telefonische-beratung/social-media-image-2.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/telefonische-beratung/social-media-image-2.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/telefonische-beratung/social-media-image-2.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/telefonische-beratung/social-media-image-2.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/telefonische-beratung/social-media-image-3.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/telefonische-beratung/social-media-image-3.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/telefonische-beratung/social-media-image-3.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/telefonische-beratung/social-media-image-3.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/telefonische-beratung/social-media-image-4.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/telefonische-beratung/social-media-image-4.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/telefonische-beratung/social-media-image-4.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/telefonische-beratung/social-media-image-4.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/telefonische-beratung/social-media-image-5.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/telefonische-beratung/social-media-image-5.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/telefonische-beratung/social-media-image-5.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/telefonische-beratung/social-media-image-5.jpg?h=600'
          }
        }
      ]
    }
  ],
  'wohntraum-finder': [
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/wohntraum-finder/social-media-image-1.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/wohntraum-finder/social-media-image-1.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/wohntraum-finder/social-media-image-1.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/wohntraum-finder/social-media-image-1.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/wohntraum-finder/social-media-image-2.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/wohntraum-finder/social-media-image-2.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/wohntraum-finder/social-media-image-2.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/wohntraum-finder/social-media-image-2.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/wohntraum-finder/social-media-image-3.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/wohntraum-finder/social-media-image-3.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/wohntraum-finder/social-media-image-3.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/wohntraum-finder/social-media-image-3.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/wohntraum-finder/social-media-image-4.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/wohntraum-finder/social-media-image-4.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/wohntraum-finder/social-media-image-4.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/wohntraum-finder/social-media-image-4.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/wohntraum-finder/social-media-image-5.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/wohntraum-finder/social-media-image-5.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/wohntraum-finder/social-media-image-5.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/wohntraum-finder/social-media-image-5.jpg?h=600'
          }
        }
      ]
    }
  ]
}
