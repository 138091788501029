
const iFrameMap = {
  GUIDE: {
    basic: {
      width: '600px',
      height: '480px',
      zoom: 0.8,
      marginTop: '-40px'
    },
    extended: {
      width: '1100px',
      height: '1200px',
      zoom: 0.32,
      marginTop: '-400px',
      marginLeft: '-370px',
      wrapperWidth: '360px'
    }
  },
  CHECKLIST: {
    basic: {
      width: '600px',
      height: '420px',
      zoom: 0.8,
      marginTop: '-10px'
    },
    extended: {
      width: '1100px',
      height: '1200px',
      zoom: 0.3,
      marginTop: '-400px',
      marginLeft: '-370px',
      wrapperWidth: '360px'
    }
  },
  LIST: {
    basic: {
      width: '1000px',
      height: '1250px',
      zoom: 0.3,
      marginTop: '-430px',
      marginLeft: '-330px',
      wrapperWidth: '900px'
    }
  },
  VALUATION: {
    basic: {
      width: '600px',
      height: '380px',
      zoom: 0.8
    }
  },
  RETURN_CALL: {
    basic: {
      width: '600px',
      height: '970px',
      zoom: 0.4,
      marginTop: '-290px'
    }
  },
  PROPERTY_REQUEST: {
    basic: {
      width: '600px',
      height: '500px',
      zoom: 0.7,
      marginTop: '-50px'
    }
  },
  QUIZZARD: {
    basic: {
      width: '600px',
      height: '400px',
      zoom: 0.7,
      marginTop: '10px'
    }
  },
  LIFE_ANNUITY: {
    basic: {
      width: '600px',
      height: '370px',
      zoom: 0.8,
      marginTop: '10px'
    }
  },
  PARTIAL_SALE: {
    basic: {
      width: '600px',
      height: '370px',
      zoom: 0.8
    }
  },
  PLOT_VALUATION: {
    basic: {
      width: '600px',
      height: '500px',
      zoom: 0.6,
      marginTop: '-50px'
    }
  }
}

export default iFrameMap
