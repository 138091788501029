<template>
  <div class="text-center">
    <v-dialog
      v-model="showDialog"
      width="700"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="activator"
          color="primary"
          fab
          x-small
          dark
          v-bind="attrs"
          v-on="on"
        >
          <span class="text-button">?</span>
        </v-btn>
      </template>

      <v-card>
        <v-card-title
          class="pa-0"
        >
          <v-toolbar
            color="primary"
            dark
            flat
          >
            <v-toolbar-title>{{ title }}</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-btn
                icon
                dark
                @click="showDialog=false"
              >
                <v-icon large>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>

        <v-card-text class="mt-5">
          <p v-html="content" />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            class="grey--text"
            text
            @click="showDialog = false"
          >
            Schliessen
          </v-btn>
          <v-btn
            v-if="link"
            text
            color="primary"
            class="ml-2"
            :href="link"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ linkText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    },
    linkText: {
      type: String,
      default: ''
    }

  },
  data () {
    return {
      showDialog: false
    }
  }
}
</script>

<style scoped>
.activator{
  max-width: 20px !important;
  max-height: 20px !important;
  box-shadow: none;
}
</style>
