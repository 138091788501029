<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 284 284"
    enable-background="new 0 0 284 284"
    xml:space="preserve"
  >

    <gradient-helper />

    <path
      fill="none"
      stroke="url(#svg-gradient)"
      stroke-width="6.499"
      stroke-miterlimit="10"
      d="M184.5,142c0-23.5-19-42.5-42.5-42.5v0c-23.5,0-42.5,19-42.5,42.5h0c0,23.5,19,42.5,42.5,42.5v0C165.5,184.5,184.5,165.5,184.5,142L184.5,142z"
    />

    <path
      fill="none"
      stroke="url(#svg-gradient)"
      stroke-width="5.763"
      stroke-miterlimit="10"
      d="M193.7,142c0-28.6-23.2-51.7-51.7-51.7v0c-28.6,0-51.7,23.2-51.7,51.7h0c0,28.6,23.2,51.7,51.7,51.7v0C170.5,193.7,193.7,170.6,193.7,142L193.7,142z"
    />

    <path
      fill="none"
      stroke="url(#svg-gradient)"
      stroke-width="5.141"
      stroke-miterlimit="10"
      d="M202.9,142c0-33.6-27.3-60.9-60.9-60.9v0c-33.7,0-60.9,27.3-60.9,60.9h0c0,33.6,27.3,60.9,60.9,60.9v0C175.6,202.9,202.9,175.6,202.9,142L202.9,142z"
    />

    <path
      fill="none"
      stroke="url(#svg-gradient)"
      stroke-width="4.852"
      stroke-miterlimit="10"
      d="M212.1,142c0-38.7-31.4-70.1-70.1-70.1v0c-38.7,0-70.2,31.4-70.2,70.1h0c0,38.7,31.4,70.1,70.1,70.1v0C180.7,212.1,212.1,180.7,212.1,142L212.1,142z"
    />

    <path
      fill="none"
      stroke="url(#svg-gradient)"
      stroke-width="4.5"
      stroke-miterlimit="10"
      d="M221.4,142c0-43.8-35.5-79.3-79.3-79.4v0c-43.8,0-79.4,35.5-79.4,79.4h0c0,43.8,35.5,79.3,79.3,79.4v0C185.8,221.3,221.3,185.8,221.4,142L221.4,142z"
    />

    <path
      fill="none"
      stroke="url(#svg-gradient)"
      stroke-width="4.032"
      stroke-miterlimit="10"
      d="M230.6,142c0-48.9-39.6-88.6-88.6-88.6v0c-48.9,0-88.6,39.6-88.6,88.6h0c0,48.9,39.6,88.6,88.6,88.6v0C190.9,230.6,230.5,190.9,230.6,142L230.6,142z"
    />

    <path
      fill="none"
      stroke="url(#svg-gradient)"
      stroke-width="3.551"
      stroke-miterlimit="10"
      d="M239.8,142c0-54-43.8-97.8-97.8-97.8v0C88,44.2,44.2,88,44.2,142h0c0,54,43.8,97.8,97.8,97.8v0C196,239.8,239.8,196,239.8,142L239.8,142z"
    />

    <path
      fill="none"
      stroke="url(#svg-gradient)"
      stroke-width="2"
      stroke-miterlimit="10"
      d="M248.3,142c0-58.7-47.6-106.3-106.3-106.3v0C83.3,35.7,35.7,83.3,35.7,142h0c0,58.7,47.6,106.3,106.3,106.3v0C200.7,248.3,248.2,200.7,248.3,142L248.3,142z"
    />

    <path
      fill="none"
      stroke="url(#svg-gradient)"
      stroke-width="1.5"
      stroke-miterlimit="10"
      d="M258.2,142c0-64.2-52-116.2-116.2-116.2v0c-64.2,0-116.2,52-116.2,116.2h0c0,64.2,52,116.2,116.2,116.2v0C206.1,258.2,258.2,206.2,258.2,142L258.2,142z"
    />

    <path
      fill="none"
      stroke="url(#svg-gradient)"
      stroke-miterlimit="10"
      d="M270.9,142c0-71.2-57.7-128.9-128.9-128.9v0c-71.2,0-129,57.7-129,128.9h0c0,71.2,57.7,128.9,128.9,128.9v0C213.2,270.9,270.9,213.2,270.9,142L270.9,142z"
    />

    <path
      fill="none"
      stroke="url(#svg-gradient)"
      stroke-width="0.5"
      stroke-miterlimit="10"
      d="M283.7,142C283.7,63.7,220.3,0.3,142,0.3v0C63.7,0.3,0.3,63.7,0.3,142h0c0,78.3,63.4,141.7,141.7,141.7v0C220.2,283.7,283.7,220.3,283.7,142L283.7,142z"
    />
  </svg>
</template>

<script>
import svg from '@/mixins/svg'
import GradientHelper from './GradientHelper.vue'
export default {
  components: { GradientHelper },
  mixins: [svg]
}
</script>
