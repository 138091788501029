/**
 * Sets query paramters on URL. Handles already existing query parameters.
 * @param {String} url base URL String
 * @param {Object} params query parameters
 * @returns url with query paramters
 */
export function setQueryParams (url, params = {}) {
  var urlObject = new URL(url)
  Object.entries(params).forEach(([key, value]) => {
    urlObject.searchParams.set(key, value)
  })
  return urlObject.toString()
}

export default {
  methods: {
    setQueryParams
  }
}
